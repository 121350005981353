<template>
  <div id="app">
    <router-view v-slot="{ Component }" :key="$route.fullPath">
      <keep-alive :include="cachedViews">
        <component :is="Component"></component>
      </keep-alive>
    </router-view>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';

  export default defineComponent({
    name: 'App',
    setup() {
      const store = useStore();
      const cachedViews = computed(() => store.getters['tabs/cacheViews']);
      return {
        cachedViews,
      };
    },
  });
</script>

<style>
  #app {
    width: 100%;
    height: 100%;
  }
</style>
