<template>
  <div class="info-container">
    <!-- <el-dropdown> -->
    <el-popover placement="bottom" :width="200" trigger="hover">
      <template #reference>
        <span class="user-avatar">
          <el-avatar :src="require('@/assets/image/avatar.png')"></el-avatar>
          {{ username }}
        </span>
      </template>
      <div class="pwd-button">
        <el-button class="button" @click="changePasswordClick">修改密码</el-button>
      </div>
    </el-popover>
    <span class="iconfont icon-exit" @click="exit"></span>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import store from '@/store';
  import { getDomain } from '../utils/urlHandler';
  import {removeCookiesAndSessions} from "@/utils/globalStorage";

  export default defineComponent({
    setup() {
      const router = useRouter();

      const domain = getDomain(window.location.href);

      const exit = () => {
        store.commit('app/setPermissions', []);
        store.commit('app/setHasLogin', false);
        removeCookiesAndSessions({domain})
        router.push('/login');
      };
      const company: any = computed(() => store.getters['userinfo/company']);

      const username = computed(
        () =>
          `${company?.value?.name ? `${company.value?.name} - ` : ''}${
            store.getters['userinfo/userName']
          }`
      );

      const changePasswordClick = () => {
        router.push('/changePassword');
      };

      return {
        username,
        exit,
        changePasswordClick,
      };
    },
  });
</script>
<style lang="less" scoped>
  .info-container {
    display: flex;
    justify-content: center;

    .user-avatar {
      display: flex;
      align-items: center;
      margin-right: 10px;
      .el-avatar {
        margin-right: 8px;
      }
    }
    .iconfont {
      cursor: pointer;
    }
  }
  .pwd-button {
    display: flex;
    justify-content: center;
    .button {
      border: none;
    }
  }
</style>
