import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "base-layout" }
const _hoisted_2 = { class: "action-container" }
const _hoisted_3 = { class: "iconExpand" }
const _hoisted_4 = { class: "deal" }
const _hoisted_5 = { class: "checkedDeal" }
const _hoisted_6 = { class: "textLink" }
const _hoisted_7 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Header = _resolveComponent("Header")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_aside, { width: "auto" }, {
          default: _withCtx(() => [
            _createVNode(_component_Menu)
          ]),
          _: 1
        }),
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_header, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("span", _hoisted_3, [
                    _createElementVNode("i", {
                      style: {"font-size":"18px","vertical-align":"middle"},
                      class: "iconfont icon-expand",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeSidebarState && _ctx.changeSidebarState(...args)))
                    })
                  ]),
                  _createVNode(_component_Breadcrumb)
                ]),
                _createVNode(_component_Header)
              ]),
              _: 1
            }),
            _createVNode(_component_el_main, null, {
              default: _withCtx(() => [
                _createVNode(_component_router_view, null, {
                  default: _withCtx(({ Component }) => [
                    (_openBlock(), _createBlock(_KeepAlive, { include: _ctx.cachedViews }, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                    ], 1032, ["include"]))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.centerDialogVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.centerDialogVisible) = $event)),
      title: "用户协议和隐私政策",
      width: "40%",
      center: "",
      onClose: _ctx.handleCancelClick
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_7, [
          _createVNode(_component_el_button, {
            onClick: _ctx.handleCancelClick,
            size: "large",
            class: "footerButton"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("取消")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.handleCertainClick,
            disabled: !_ctx.checked,
            size: "large",
            class: "footerButton"
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("同意 ")
            ])),
            _: 1
          }, 8, ["onClick", "disabled"])
        ])
      ]),
      default: _withCtx(() => [
        _cache[8] || (_cache[8] = _createElementVNode("p", { class: "dealText" }, "请您务必审慎阅读、充分理解《云彩人资科研企业版隐私政策》和《云彩人资科研用户协议》各条款，包括但不限于：为了更好的向您提供服务，我们需要收集您的设备标识、操作日志等信息用于分析、优化应用性能。", -1)),
        _cache[9] || (_cache[9] = _createElementVNode("p", { class: "dealText" }, "您可阅读《云彩人资科研企业版隐私政策》和《云彩人资科研用户协议》了解详情信息。如果您同意，请点击下面按钮开始接受我们的服务。", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_checkbox, {
              modelValue: _ctx.checked,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checked) = $event))
            }, null, 8, ["modelValue"]),
            _createElementVNode("span", _hoisted_6, [
              _cache[5] || (_cache[5] = _createTextVNode(" 我已阅读并同意遵守")),
              _createVNode(_component_el_link, {
                type: "primary",
                target: "_blank",
                href: "/privacyDeal"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("《云彩人资科研企业版隐私政策》")
                ])),
                _: 1
              }),
              _createVNode(_component_el_link, {
                type: "primary",
                target: "_blank",
                href: "/userDeal"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("《云彩人资科研用户协议》")
                ])),
                _: 1
              })
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "onClose"])
  ]))
}