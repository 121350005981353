const router = {
  path: '/homepage',
  name: 'Homepage',
  component: () => import('@/views/homepage/index.vue'),
  meta: {
    title: '首页',
    icon: 'iconfont icon-shouye',
    hide: false,
    permissionKey: 'menu-normal',
    showChildren: false,
    sequence: 1,
  },
};

export default router;
