import Cookies from "js-cookie";
import router from "@/router";

//登录 set 全局存储
export const loginAndNativeHomepage = (token: string, options: {}) => {
  sessionStorage.setItem('token', token);
  Cookies.set('token', token, options);
  Cookies.set('imitateLogin', 'false', options);
  router.push('/homepage');
};

//登陆后获取基本信息的 set 全局存储
export const setCookiesAndSessions = (cookieData, sessionData, options:{}): void => {
  Cookies.set('userId', cookieData?.userId, options);
  Cookies.set('userName', cookieData.userName, options);
  Cookies.set('tenantId', cookieData?.tenantId, options);
  Cookies.set('tenantName', cookieData?.tenantName, options);
  Cookies.set('root', cookieData?.root, options);
  Cookies.set('investorUser', cookieData?.investorUser, options);
  Cookies.set('securityManager', cookieData?.securityManager, options);

  sessionStorage.setItem('userId', sessionData?.userId);
  sessionStorage.setItem('userName', sessionData?.userName);
  sessionStorage.setItem('tenantId', sessionData?.tenantId);
  sessionStorage.setItem('tenantName', sessionData?.tenantName);
  sessionStorage.setItem('root', sessionData?.root);
  sessionStorage.setItem('investorUser', sessionData?.investorUser);
  sessionStorage.setItem('securityManager', sessionData?.securityManager);
};

//移除基本信息的全局存储
export const removeCookiesAndSessions = (options:{}): void => {
  Cookies.remove('token', options);
  Cookies.remove('userId', options)
  Cookies.remove('userName',  options);
  Cookies.remove('tenantId', options);
  Cookies.remove('tenantName', options);
  Cookies.remove('root', options);
  Cookies.remove('investorUser', options);
  Cookies.remove('securityManager', options);
  Cookies.remove('imitateLogin', options);

  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('userName');
  sessionStorage.removeItem('tenantId');
  sessionStorage.removeItem('tenantName');
  sessionStorage.removeItem('root');
  sessionStorage.removeItem('investorUser');
  sessionStorage.removeItem('securityManager');
};

//模拟用户后 set cookie token
export const setImitateUserStorage = (token: string, userId: string, imitateLogin: string, options: {}): void => {
  Cookies.set('token', token,  options);
  Cookies.set('userId', userId,  options);
  Cookies.set('imitateLogin',imitateLogin, options);
}
