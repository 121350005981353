const router = {
    index: 0,
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/index.vue'),
    meta: {
      title: '设置',
      icon: 'iconfont icon-settings',
      hide: false,
      permissionKey: 'settings',
      showChildren: true,
      sequence: 7,
    }
};

export default router;
