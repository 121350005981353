/* eslint-disable no-param-reassign */
import type { AxiosInstance, AxiosResponse } from 'axios';
import axios from 'axios';
import { ElMessage } from 'element-plus';

import store from '@/store';

import { AxiosCanceler } from './axiosCanceler';
import {removeCookiesAndSessions} from "@/utils/globalStorage";
import {getDomain} from "@/utils/urlHandler";

export function getToken() {
  return sessionStorage.getItem('token');
}

export function redirectToPortal() {
  ElMessage({
    message: '登录失效，请重新登录',
    duration: 1000,
    type: 'error',
    onClose: () => {
      const loginHref = `${window.location.origin}/login`;

      if (window.location.href !== loginHref) {
        window.location.href = loginHref;
      }
    },
  });
}

const instance: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// request interceptor
instance.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers['X-User-Token'] = getToken();
    }
    AxiosCanceler.removePendingAjax(config);
    AxiosCanceler.addPendingAjax(config);
    if (config.method === 'get') {
      config.params = {
        ...config.params,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    AxiosCanceler.removePendingAjax(response);
    const res: any =
      response.headers['content-disposition']?.split(';')[0] === 'attachment'
        ? response
        : response.data;
    if (res.code === 1) {
      ElMessage.error(res.msg || '出错了');
      return Promise.reject(response);
    }
    return Promise.resolve(res);
  },
  (error) => {
    if (error.response.status === 401) {
      const domain = getDomain(window.location.href);
      removeCookiesAndSessions({domain});
      store.commit('app/setHasLogin', false);
      redirectToPortal();
    }
    // TODO
    return Promise.reject(error);
  }
);

export default instance;
