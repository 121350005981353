import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "info-container" }
const _hoisted_2 = { class: "user-avatar" }
const _hoisted_3 = { class: "pwd-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_popover, {
      placement: "bottom",
      width: 200,
      trigger: "hover"
    }, {
      reference: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_component_el_avatar, {
            src: require('@/assets/image/avatar.png')
          }, null, 8, ["src"]),
          _createTextVNode(" " + _toDisplayString(_ctx.username), 1)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_button, {
            class: "button",
            onClick: _ctx.changePasswordClick
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("修改密码")
            ])),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }),
    _createElementVNode("span", {
      class: "iconfont icon-exit",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.exit && _ctx.exit(...args)))
    })
  ]))
}