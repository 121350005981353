<template>
  <div class="base-layout">
    <el-container>
      <el-aside width="auto"><Menu /></el-aside>
      <el-container>
        <el-header>
          <div class="action-container">
            <!-- <el-button
              circle
              :icon="sidebarState ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
              @click="changeSidebarState"
            ></el-button> -->

            <span class="iconExpand"
              ><i
                style="font-size: 18px; vertical-align: middle"
                class="iconfont icon-expand"
                @click="changeSidebarState"
              ></i
            ></span>
            <Breadcrumb />
          </div>
          <Header />
        </el-header>
        <el-main>
          <router-view v-slot="{ Component }">
            <keep-alive :include="cachedViews">
              <component :is="Component"></component>
            </keep-alive>
          </router-view>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog
      v-model="centerDialogVisible"
      title="用户协议和隐私政策"
      width="40%"
      center
      @close="handleCancelClick"
    >
      <p class="dealText"
        >请您务必审慎阅读、充分理解《云彩人资科研企业版隐私政策》和《云彩人资科研用户协议》各条款，包括但不限于：为了更好的向您提供服务，我们需要收集您的设备标识、操作日志等信息用于分析、优化应用性能。</p
      >
      <p class="dealText"
        >您可阅读《云彩人资科研企业版隐私政策》和《云彩人资科研用户协议》了解详情信息。如果您同意，请点击下面按钮开始接受我们的服务。</p
      >
      <div class="deal">
        <div class="checkedDeal">
          <el-checkbox v-model="checked"></el-checkbox>
          <span class="textLink">
            我已阅读并同意遵守<el-link type="primary" target="_blank" href="/privacyDeal"
              >《云彩人资科研企业版隐私政策》</el-link
            >
            <el-link type="primary" target="_blank" href="/userDeal"
              >《云彩人资科研用户协议》</el-link
            >
          </span>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleCancelClick" size="large" class="footerButton">取消</el-button>
          <el-button
            type="primary"
            @click="handleCertainClick"
            :disabled="!checked"
            size="large"
            class="footerButton"
            >同意
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted, reactive, toRefs } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import Cookies from 'js-cookie';
  import {querySessionUserInfo, queryUserInfo, signAgreement} from '@/api/userinfo';
  import { getDomain } from '@/utils/urlHandler';
  import Breadcrumb from './Breadcrumb.vue';
  import Header from './Header.vue';
  import Menu from './Menu';
  import MutilTab from './MutilTab.vue';
  import {removeCookiesAndSessions, setCookiesAndSessions} from '@/utils/globalStorage';

  export default defineComponent({
    name: 'Layout',
    components: {
      Menu,
      Header,
      MutilTab,
      Breadcrumb,
    },
    setup() {
      const router = useRouter();
      const store = useStore();
      const state = reactive({
        centerDialogVisible: false,
        checked: false,
        isAgree: false,
      });
      const sidebarState = computed(() => store.getters['app/sidebarState']);
      const sidebarStateFlag = computed(() => store.getters['app/sidebarStateFlag']);
      const cachedViews = computed(() => store.getters['tabs/cacheViews']);
      const changeSidebarState = () => {
        // ev.target.blur();
        store.commit('app/setSidebarStateFlag', !sidebarStateFlag.value);
        store.commit('app/setSidebarState', !sidebarState.value);
        //
      };

      const domain = getDomain(window.location.href);

      async function getUserInfo() {
        //获取cookie token 对应的用户信息
        const cookieTokenRes = await queryUserInfo();
        const cookieData = cookieTokenRes.data;
        store.commit('userinfo/setUserName', cookieData.userName);
        store.commit('userinfo/setIsAdmin', cookieData.isAdmin);
        store.commit('userinfo/setCompany', {
          brand: cookieData?.brandUrl,
          logo: cookieData?.logoUrl,
          name: cookieData?.tenantName,
        });
        if (Cookies.get('imitateLogin') === 'true') {
          //如果是模拟登录，需要获取 session token 里原本的用户信息
          const sessionTokenRes = await querySessionUserInfo();
          const sessionData = sessionTokenRes.data;
          setCookiesAndSessions(cookieData, sessionData, {domain});
        } else {
          setCookiesAndSessions(cookieData, cookieData, {domain});
        }
        //是否显示协议
        state.centerDialogVisible = cookieData.root && !cookieData.signed;
      }

      onMounted(() => {
        getUserInfo();
      });
      const handleCancelClick = () => {
        if (!state.isAgree) {
          store.commit('app/setPermissions', []);
          store.commit('app/setHasLogin', false);
          removeCookiesAndSessions({domain});
          router.push('/login');
        }
        state.centerDialogVisible = false;
      };
      const handleCertainClick = async () => {
        await signAgreement();
        state.isAgree = true;
        state.centerDialogVisible = false;
      };

      return {
        ...toRefs(state),
        sidebarState,
        cachedViews,
        sidebarStateFlag,
        changeSidebarState,
        handleCancelClick,
        handleCertainClick,
      };
    },
  });
</script>
<style lang="less" scoped>
  .base-layout {
    display: flex;
    width: 100%;
    min-height: 100%;
    background: #f4f7f9;
    flex-direction: column;
    .el-aside {
      height: 100vh;
    }
    .el-container {
      .el-header {
        display: flex;
        height: 48px;
        padding: 0 20px;
        margin-left: -1px;
        line-height: 48px;
        align-items: center;

        // color: #fff;
        justify-content: space-between;
        background: #fff;
        border-bottom: 1px solid #eee;
        border-left: 1px solid #eee;
        .action-container {
          display: flex;
          align-items: center;
          .iconExpand {
            color: #666;
            margin-right: 12px;
            cursor: pointer;
          }
          .iconExpand:hover {
            color: #3293e2;
          }
        }
      }
    }
  }
  .deal {
    display: flex;
  }
  .dealText {
    text-indent: 2em;
  }
  .checkedDeal {
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  .textLink {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
  .footerButton {
    margin: 0 50px !important;
  }
</style>
