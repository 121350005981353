import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "tabnavBox" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, {
      name: "list",
      tag: "ul"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabnavBox, (item, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(["tabnav", { active: _ctx.tabsValue === item.path }]),
            key: item.path
          }, [
            _createVNode(_component_router_link, {
              to: item.path
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.title), 1)
              ]),
              _: 2
            }, 1032, ["to"]),
            (index !== 0)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: "el-icon-error",
                  onClick: ($event: any) => (_ctx.removeTab(item))
                }, null, 8, _hoisted_2))
              : _createCommentVNode("", true)
          ], 2))
        }), 128))
      ]),
      _: 1
    })
  ]))
}