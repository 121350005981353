import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_component_router_view, {
      key: _ctx.$route.fullPath
    }, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_KeepAlive, { include: _ctx.cachedViews }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ], 1032, ["include"]))
      ]),
      _: 1
    }))
  ]))
}