import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "el-dropdown-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!

  return (_openBlock(), _createBlock(_component_el_breadcrumb, { separator: "/" }, {
    default: _withCtx(() => [
      _createVNode(_TransitionGroup, { name: "breadcrumb" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.matchedRoutes, (item) => {
            return (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
              key: item.path
            }, {
              default: _withCtx(() => [
                (item.children.length)
                  ? (_openBlock(), _createBlock(_component_el_dropdown, {
                      key: 0,
                      onCommand: _ctx.handleCommand
                    }, {
                      dropdown: _withCtx(() => [
                        _createVNode(_component_el_dropdown_menu, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                              return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                key: child.name,
                                command: child.path
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(child.meta.title), 1)
                                ]),
                                _: 2
                              }, 1032, ["command"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_1, [
                          _createTextVNode(" 当前位置:" + _toDisplayString(item.meta.title), 1),
                          _cache[0] || (_cache[0] = _createElementVNode("i", { class: "el-icon-arrow-down el-icon--right" }, null, -1))
                        ])
                      ]),
                      _: 2
                    }, 1032, ["onCommand"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(" 当前位置:" + _toDisplayString(item.meta.title), 1)
                    ], 64))
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}