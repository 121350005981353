import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/index.vue';

const files = require.context('.', false, /\.ts$/);

let routes: RouteRecordRaw[] = [];
files.keys().forEach((filename: string) => {
  if (filename === './index.ts') return;
  routes = routes.concat(files(filename).default);
});

// 根据 meta.sequence 字段排序
routes.sort((a, b) => {
  // 如果没有 sequence 字段，则给一个默认的大数字，确保它排在最后
  const sequenceA = typeof a.meta?.sequence === "number" ? a.meta.sequence : Number.MAX_SAFE_INTEGER;
  const sequenceB = typeof b.meta?.sequence === "number" ? b.meta.sequence : Number.MAX_SAFE_INTEGER;
  return sequenceA - sequenceB; // 按照升序排序
});

export const configRoutes = routes;


export const RootRoute = {
  path: '/',
  name: 'Root',
  component: Layout,
  redirect: '/login',
  meta: {
    title: 'Root',
  },
  children: [],
};
const systemRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登陆',
    },
  },
  {
    path: '/changePassword',
    component: () => import('@/views/changePassword/index.vue'),
    meta: {
      title: 'changePassword',
    },
  },
  {
    path: '/404',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '未找到该页面',
    },
  },
  {
    path: '/privacyPolicy',
    component: () => import('@/views/doc/privacyPolicy/index.vue'),
    meta: {
      title: '隐私政策',
    },
  },
  {
    path: '/userAgreement',
    component: () => import('@/views/doc/userAgreement/index.vue'),
    meta: {
      title: '用户协议',
    },
  },
  {
    path: '/privacyDeal',
    component: () => import('@/views/privacyDeal/index.vue'),
    meta: {
      title: '隐私政策',
    },
  },
  {
    path: '/userDeal',
    component: () => import('@/views/userDeal/index.vue'),
    meta: {
      title: '用户注册协议',
    },
  },
  {
    path: '/callback',
    component: () => import('@/views/callback/index.vue'),
    meta: {
      title: '微信扫码登录',
    },
  },
  {
    path: '/bindPhone',
    component: () => import('@/views/bindPhone/index.vue'),
    meta: {
      title: '绑定手机号',
    },
  },
  {
    path: '/forgetPwd',
    component: () => import('@/views/forgetPwd/index.vue'),
    meta: {
      title: '忘记密码',
    },
  },
];
export const basicRoutes = [RootRoute, ...systemRoutes];
