const router = [
  {
    path: '/userManage',
    name: 'userManage',
    component: () => import('@/views/userManage/userManage.vue'),
    meta: {
      title: '用户管理',
      icon: 'iconfont icon-yonghuguanli',
      hide: false,
      permissionKey: 'menu-admin',
      showChildren: true,
      sequence: 4,
    },
  },
  {
    path: '/addUser',
    name: 'addUser',
    component: () => import('@/views/userManage/addUser.vue'),
    meta: {
      title: '添加用户',
      icon: 'el-icon-menu',
      hide: true,
      permissionKey: 'menu-admin',
      showChildren: false,
    },
  },
  {
    path: '/editUser',
    name: 'editUser',
    component: () => import('@/views/userManage/editUser.vue'),
    meta: {
      title: '编辑用户',
      icon: 'el-icon-menu',
      hide: true,
      permissionKey: 'menu-admin',
      showChildren: false,
    },
  },
];

export default router;
