import { createApp } from 'vue';
import SvgIcon from '@/components/SvgIcon.vue';
import App from './App.vue';
import router, { setupRouter } from './router';
import { setupStore } from './store';
import installElementPlus from './plugins/element';
import 'element-plus/dist/index.css'; // 引入 element-plus 的样式文件
import './styles/index.less';
import './icons'; // icons
import './icons/font_icon/iconfont.css';

const app = createApp(App);
installElementPlus(app);
setupRouter(app);

setupStore(app);

app.component('SvgIcon', SvgIcon); // global register icon component

router.isReady().then(() => {
  app.mount('#app');
});
