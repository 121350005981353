/* eslint-disable no-shadow */
import {getCharacteristicData, getCharacteristicDataBySessionToken} from '@/api/system';
import Cookies from "js-cookie";

const state = {
  hasLogin: false,
  permissions: [],
  sidebarState: true,
  sidebarStateFlag: true,
};

const getters = {
  hasLogin: (state: any) => state.hasLogin,
  permissions: (state: any) => state.permissions,
  sidebarState: (state: any) => state.sidebarState,
  sidebarStateFlag: (state: any) => state.sidebarStateFlag,
};

const mutations = {
  setHasLogin(state: any, data: boolean) {
    state.hasLogin = data;
  },
  setPermissions(state: any, permissions: string[]) {
    state.permissions = permissions;
  },
  setSidebarState(state: any, data: boolean) {
    state.sidebarState = data;
    console.log(state.sidebarState);
  },
  setSidebarStateFlag(state: any, data: boolean) {
    state.sidebarStateFlag = data;
    console.log(state.sidebarStateFlag);
  },
};

const actions = {
  async getPermissions({ commit }: any) {
    const { data } = await getCharacteristicData();
    const permissions = data?.root
      ? ['menu-normal', 'menu-admin', 'menu-corporation']
      : ['menu-normal'];
    //开发者和资金方才有模拟用户的菜单（模拟菜单的渲染要以session的token为准，即原来的用户而非模拟的用户）
    const sessionTokenRes = await getCharacteristicDataBySessionToken();
    if (sessionTokenRes?.data?.devUser || sessionTokenRes?.data?.investorUser) {
      permissions.push('devUser');
    }
    //不是模拟登陆才有设置菜单
    if (Cookies.get('imitateLogin') === 'false') {
      permissions.push('settings');
    }

    // const data: string[] = dataBolean.data ? ['menu-normal', 'menu-admin'] : ['menu-normal'];
    commit('setPermissions', permissions);
    commit('setHasLogin', true);
    return permissions;
  },
  resetPermissions({ commit }: any) {
    commit('setPermissions', []);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
